var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(" " + _vm._s(_vm.banner.idBanner === 0 ? "Crear Banner Invasivo" : "Modificar Banner Invasivo"))]), _c('div', [_c('b-form-group', {
    attrs: {
      "label": "Activo",
      "label-for": "vi-activo"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-activo",
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.banner.estado,
      callback: function callback($$v) {
        _vm.$set(_vm.banner, "estado", $$v);
      },
      expression: "banner.estado"
    }
  })], 1)], 1)], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "InfoIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-nombre",
      "placeholder": "Nombre del banner"
    },
    model: {
      value: _vm.banner.nombre,
      callback: function callback($$v) {
        _vm.$set(_vm.banner, "nombre", $$v);
      },
      expression: "banner.nombre"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Inicio",
      "label-for": "vi-fecha-ini"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "vi-fecha-ini",
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    },
    model: {
      value: _vm.banner.fechaDesde,
      callback: function callback($$v) {
        _vm.$set(_vm.banner, "fechaDesde", $$v);
      },
      expression: "banner.fechaDesde"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Inicio",
      "label-for": "vi-hora-ini"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "id": "vi-hora-ini",
      "locale": "en"
    },
    model: {
      value: _vm.banner.horaDesde,
      callback: function callback($$v) {
        _vm.$set(_vm.banner, "horaDesde", $$v);
      },
      expression: "banner.horaDesde"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Fin",
      "label-for": "vi-fecha-fin"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "vi-fecha-fin",
      "min": _vm.banner.fechaDesde,
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    },
    model: {
      value: _vm.banner.fechaHasta,
      callback: function callback($$v) {
        _vm.$set(_vm.banner, "fechaHasta", $$v);
      },
      expression: "banner.fechaHasta"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Fin",
      "label-for": "vi-hora-fin"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "id": "vi-hora-fin",
      "locale": "en"
    },
    model: {
      value: _vm.banner.horaHasta,
      callback: function callback($$v) {
        _vm.$set(_vm.banner, "horaHasta", $$v);
      },
      expression: "banner.horaHasta"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bodegas",
      "label-for": "vi-bodegas"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-bodegas",
      "label": "nombre",
      "reduce": function reduce(b) {
        return b.id;
      },
      "options": _vm.bodegas,
      "loading": _vm.loadingBodegas,
      "multiple": ""
    },
    model: {
      value: _vm.banner.bodegas,
      callback: function callback($$v) {
        _vm.$set(_vm.banner, "bodegas", $$v);
      },
      expression: "banner.bodegas"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-img', {
    attrs: {
      "src": _vm.urlResized(_vm.banner.imagen),
      "width": "200",
      "height": "200",
      "rounded": ""
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm.uploadingFile ? _c('div', {
    staticClass: "ldr"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Loading..."
    }
  })], 1) : _c('b-form-group', {
    attrs: {
      "label": "Imagen [1000 x 1500]",
      "label-for": "vi-imagen"
    }
  }, [_c('b-form-file', {
    attrs: {
      "id": "vi-imagen",
      "accept": ".jpg, .png",
      "placeholder": "Imagen",
      "browse-text": "Seleccionar"
    },
    on: {
      "change": function change($event) {
        return _vm.encodeImageFile($event);
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tipo cliente",
      "label-for": "vi-tipos"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-tipos",
      "label": "title",
      "reduce": function reduce(t) {
        return t.value;
      },
      "options": _vm.tiposUsuarios,
      "clearable": false
    },
    model: {
      value: _vm.banner.tiposUsuarios,
      callback: function callback($$v) {
        _vm.$set(_vm.banner, "tiposUsuarios", $$v);
      },
      expression: "banner.tiposUsuarios"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.loading || _vm.uploadingFile
    },
    on: {
      "click": _vm.saveData
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }