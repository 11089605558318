<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        {{
          banner.idBanner === 0
            ? "Crear Banner Invasivo"
            : "Modificar Banner Invasivo"
        }}</b-card-title
      >
      <div>
        <b-form-group label="Activo" label-for="vi-activo">
          <b-form-checkbox
            id="vi-activo"
            v-model="banner.estado"
            class="custom-control-primary"
            name="check-button"
            switch
          />
        </b-form-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Nombre" label-for="vi-nombre">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="InfoIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-nombre"
                v-model="banner.nombre"
                placeholder="Nombre del banner"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group label="Fecha Inicio" label-for="vi-fecha-ini">
            <b-form-datepicker id="vi-fecha-ini" v-model="banner.fechaDesde" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group label="Hora Inicio" label-for="vi-hora-ini">
            <b-form-timepicker
              id="vi-hora-ini"
              locale="en"
              v-model="banner.horaDesde"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group label="Fecha Fin" label-for="vi-fecha-fin">
            <b-form-datepicker
              id="vi-fecha-fin"
              :min="banner.fechaDesde"
              v-model="banner.fechaHasta"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group label="Hora Fin" label-for="vi-hora-fin">
            <b-form-timepicker
              id="vi-hora-fin"
              locale="en"
              v-model="banner.horaHasta"
            />
          </b-form-group>
        </b-col>

        <!-- Bodegas -->
        <b-col cols="12">
          <b-form-group label="Bodegas" label-for="vi-bodegas">
            <v-select
              id="vi-bodegas"
              label="nombre"
              v-model="banner.bodegas"
              :reduce="(b) => b.id"
              :options="bodegas"
              :loading="loadingBodegas"
              multiple
            />
          </b-form-group>
        </b-col>

        <!-- Imagenes -->
        <b-col cols="12" md="6">
          <b-img
            :src="urlResized(banner.imagen)"
            width="200"
            height="200"
            rounded
          />
        </b-col>

        <b-col cols="12" md="6">
          <div v-if="uploadingFile" class="ldr">
            <b-spinner type="grow" label="Loading..." />
          </div>
          <b-form-group v-else label="Imagen [1000 x 1500]" label-for="vi-imagen">
            <b-form-file
              id="vi-imagen"
              accept=".jpg, .png"
              @change="encodeImageFile($event)"
              placeholder="Imagen"
              browse-text="Seleccionar"
            />
          </b-form-group>

          <b-form-group label="Tipo cliente" label-for="vi-tipos">
            <v-select
              id="vi-tipos"
              label="title"
              v-model="banner.tiposUsuarios"
              :reduce="(t) => t.value"
              :options="tiposUsuarios"
              :clearable="false"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="mt-2">
          <b-button
            block
            @click="saveData"
            variant="primary"
            class="mr-1"
            :disabled="loading || uploadingFile"
          >
            <b-spinner v-if="loading" small label="Loading..." />
            <div v-else>Guardar</div>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>


<script>
import {
  BRow,
  BCol,
  BImg,
  BCard,
  BForm,
  BButton,
  BSpinner,
  BCardBody,
  BCardText,
  BFormFile,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BCardHeader,
  BInputGroup,
  BButtonGroup,
  BFormCheckbox,
  BFormTimepicker,
  BFormDatepicker,
  BFormRadioGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
import { getUserID, getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BForm,
    BButton,
    vSelect,
    BSpinner,
    BFormFile,
    BCardBody,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BInputGroup,
    BButtonGroup,
    BFormCheckbox,
    BFormTimepicker,
    BFormDatepicker,
    BFormRadioGroup,
    BInputGroupPrepend,
  },
  props: {
    sBanner: {
      type: Object,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      banner: null,
      loading: false,
      bodegas: [],
      sectores: [],
      uploadingFile: false,
      loadingBodegas: true,
      tiposUsuarios: [
        {
          title: "Todos",
          value: "ALL",
        },
        {
          title: "Latebuy",
          value: "CLATEBUY",
        },
        {
          title: "Mamá VIP",
          value: "CMAMAVIP",
        },
        {
          title: "Nuevo",
          value: "CNUEVO",
        },
        {
          title: "Recuperado",
          value: "CRECUPERADO",
        },
        {
          title: "V.I.P.",
          value: "CVIP",
        },
        {
          title: "Normal",
          value: "NORMAL",
        },
        {
          title: "Primera Compra",
          value: "1ERCOMPRA",
        },
        {
          title: "Segunda Compra",
          value: "2DACOMPRA",
        },
        {
          title: "Tercera Compra",
          value: "3ERCOMPRA",
        },
      ],
    };
  },
  beforeMount() {
    const banner = JSON.parse(JSON.stringify(this.sBanner));
    banner.fechaDesde = moment(banner.fechaDesde).toDate();
    banner.fechaHasta = moment(banner.fechaHasta).toDate();
    this.banner = banner;
  },
  mounted() {
    this.getSectorsWarehouses();
  },
  methods: {
    getSectorsWarehouses() {
      this.loadingBodegas = true;
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/SectoresBodegas",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.bodegas = res.data.lsbodegas;
              this.sectores = res.data.lssectores;
              resolve();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            reject(e.message);
          })
          .finally(() => {
            this.loadingBodegas = false;
          });
      });
    },
    urlResized(urlOriginal) {
      try {
        const url = new URL(urlOriginal);
        url.pathname = "/fit-in/200x200" + url.pathname;
        return url.href;
      } catch (error) {
        return "https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg";
      }
    },
    encodeImageFile(element) {
      const uploadImage = this.uploadImage;
      const filesSelected = element.currentTarget.files;
      if (filesSelected.length > 0) {
        const fileToLoad = filesSelected[0];
        const fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
          const srcData = fileLoadedEvent.target.result;
          uploadImage(srcData);
        };
        fileReader.readAsDataURL(fileToLoad);
      }
    },
    uploadImage(imagenSRC) {
      this.uploadingFile = true;
      this.$http
        .post(this.$store.state.app.middlewareAWSURL, {
          path: "/Administracion/AWSUpload",
          body: JSON.stringify({
            files: imagenSRC,
            folder: 'banners',
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.banner.imagen = res.data[0].url;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: `Ocurrió un error al subir la imagen (${e.message}).`,
              icon: "AlertTriangleIcon",
              title: "Error",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.uploadingFile = false;
        });
    },
    saveData(e) {
      e.preventDefault();
      this.loading = true;
      this.banner.opt = this.banner.idBanner === 0 ? "I" : "U";
      this.banner.idUsuario = getUserID();
      this.banner.textoBoton = "";
      this.banner.tokenSesion = getAuthToken();
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/BannerInvasivo",
          body: JSON.stringify(this.banner),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.refreshData().then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Éxito",
                  icon: "CheckIcon",
                  text:
                    this.banner.idBanner === 0
                      ? "Se creo el banner correctamente"
                      : "Se actualizo correctamente el banner.",
                  variant: "success",
                },
              });
            });
            if (this.banner.idBanner === 0) {
              this.banner.idBanner = res.data.iDtemp;
            }
            this.refreshCache();
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text:
                this.banner.idBanner === 0
                  ? `Ocurrió un error al Crear el banner (${e.message}).`
                  : `Ocurrió un error al actualizar el banner (${e.message}).`,
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refreshCache() {
      this.banner.bodegas.forEach((b) => {
        this.sectores.forEach((s) => {
          this.$http.post(this.$store.state.app.nodeMiddleware, {
            path: "/Customer/AppHome",
            body: {
              tipo: "MISC",
              menu: true,
              bodega: b,
              sector: s.id,
              tokenSesion: getAuthToken(),
              refreshCache: true,
            },
          });
        });
      });
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.item-cnt {
  display: flex;
  align-items: center;
}
.iconEmpty {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

