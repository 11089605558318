<template>
  <div>
    <b-alert variant="danger" :show="error.length > 0">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrio un error al obtener las Banners {{ error }}
      </div>
    </b-alert>

    <div
      style="text-align: center; margin: 45vh 0"
      v-if="loading && rows.length === 0"
    >
      <b-spinner label="Loading..." />
    </div>

    <b-row v-else>
      <b-col cols="12" md="6">
        <b-overlay :show="loading">
          <b-card>
            <b-card-header class="p-0">
              <b-card-title>Banner Invasivos</b-card-title>
              <b-button @click="createBanner" variant="primary">
                Crear
              </b-button>
            </b-card-header>

            <div class="custom-search d-flex justify-content-end mt-2">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Buscar</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Buscar"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table
              :rows="rows"
              :columns="columns"
              :sort-options="{
                enabled: false,
              }"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Fecha Inicio -->
                <span v-if="props.column.field === 'fechaIni'">
                  <p class="mb-0">{{ formattedDate(props.row.fechaDesde) }}</p>
                  <p class="mb-0">{{ formattedHour(props.row.horaDesde) }}</p>
                </span>
                <!-- Column: Fecha Inicio -->
                <span v-else-if="props.column.field === 'fechaFin'">
                  <p class="mb-0">{{ formattedDate(props.row.fechaHasta) }}</p>
                  <p class="mb-0">{{ formattedHour(props.row.horaHasta) }}</p>
                </span>
                <!-- Column: Estado -->
                <span v-else-if="props.column.field === 'estado'">
                  <b-badge :variant="statusVariant(props.row)">
                    {{ statusText(props.row) }}
                  </b-badge>
                </span>
                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="editBanner($event, props.row)">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Editar</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="confirmDeleteBanner($event, props.row)"
                      >
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Eliminar</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>

                <!-- Column: Otras Columnas -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Cantidad de Banners </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '15', '20']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </b-overlay>
      </b-col>

      <b-col cols="12" md="6">
        <crear-editar
          v-if="selectedBanner !== null"
          :s-banner="selectedBanner"
          :refreshData="getBannerData"
        />
        <div v-else class="iconEmpty">
          <feather-icon icon="InfoIcon" size="100" />
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="modal-error"
      ok-only
      ok-variant="danger"
      ok-title="Accept"
      modal-class="modal-danger"
      centered
      ref="modal-error"
      title="Error"
    >
      <b-card-text>
        {{ error }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BAlert,
  BAvatar,
  BButton,
  BOverlay,
  BSpinner,
  BDropdown,
  BCardText,
  BFormGroup,
  BFormInput,
  BCardTitle,
  BPagination,
  BFormSelect,
  BCardHeader,
  BDropdownItem,
} from "bootstrap-vue";
import moment from "moment";
import CrearEditar from "./CrearEditar.vue";
import { VueGoodTable } from "vue-good-table";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAlert,
    BAvatar,
    BButton,
    BOverlay,
    BSpinner,
    BDropdown,
    BCardText,
    BFormGroup,
    BFormInput,
    BCardTitle,
    CrearEditar,
    BPagination,
    BFormSelect,
    BCardHeader,
    VueGoodTable,
    BDropdownItem,
  },
  data() {
    return {
      rows: [],
      error: "",
      loading: true,
      columns: [
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Fecha Inicio",
          field: "fechaIni",
        },
        {
          label: "Fecha Fin",
          field: "fechaFin",
        },
        {
          label: "Estado",
          field: "estado",
        },
        {
          label: "",
          field: "action",
        },
      ],
      searchTerm: "",
      pageLength: 10,
      selectedBanner: null,
    };
  },
  created() {
    this.getBannerData();
  },
  computed: {
    formattedDate() {
      return (s) => moment(s).format("DD/MM/YYYY");
    },
    formattedHour() {
      return (s) => moment(s, "HH:mm:ss").format("hh:mm a");
    },
  },
  methods: {
    statusText(d) {
      if (d.estado) {
        if (
          moment().isSameOrAfter(moment(d.fechaDesde)) &&
          moment().isSameOrBefore(moment(d.fechaHasta))
        ) {
          if (
            moment().isSameOrAfter(moment(d.horaDesde, "HH:mm:ss")) &&
            moment().isSameOrBefore(moment(d.horaHasta, "HH:mm:ss"))
          ) {
            return "Activo";
          } else return "Fuera de horario";
        } else {
          if (moment().isBefore(moment(d.fechaDesde))) return "No iniciado";
          else return "Finalizado";
        }
      } else {
        return "Eliminado";
      }
    },
    statusVariant(d) {
      if (d.estado) {
        if (
          moment().isSameOrAfter(moment(d.fechaDesde)) &&
          moment().isSameOrBefore(moment(d.fechaHasta))
        ) {
          if (
            moment().isSameOrAfter(moment(d.horaDesde, "HH:mm:ss")) &&
            moment().isSameOrBefore(moment(d.horaHasta, "HH:mm:ss"))
          ) {
            return "light-success";
          } else return "light-warning";
        } else {
          if (moment().isBefore(moment(d.fechaDesde))) return "light-info";
          else return "light-secondary";
        }
      } else {
        return "light-danger";
      }
    },
    editBanner(event, banner) {
      event.preventDefault();
      this.selectedBanner = null;
      this.$nextTick(() => {
        this.selectedBanner = banner;
      });
    },
    confirmDeleteBanner(event, banner) {
      event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Desea eliminar el banner invasivo?.", {
          size: "sm",
          title: "Confirmacion",
          okTitle: "Eliminar",
          centered: true,
          okVariant: "danger",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.deleteBanner(banner);
          }
        });
    },
    createBanner() {
      this.selectedBanner = null;
      this.$nextTick(() => {
        this.selectedBanner = {
          idBanner: 0,
          estado: false,
          nombre: "",
        };
      });
    },
    deleteBanner(banner) {
      if (
        this.selectedBanner &&
        this.selectedBanner.idBanner === banner.idBanner
      ) {
        this.selectedBanner = null;
      }
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/BannerInvasivo",
          body: JSON.stringify({
            Opt: "D",
            IDBanner: banner.idBanner,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.getBannerData().then(() => {
              this.$toast({
                props: {
                  icon: "BellIcon",
                  text: `Se elimino correctamente el Banner Invasivo`,
                  title: "Exito",
                },
                component: ToastificationContent,
              });
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al eliminar el Banner Invasivo [${e.message}].`,
              title: "Error",
              variant: "danger",
            },
            component: ToastificationContent,
          });
        });
    },
    onBannerClick(params) {
      const BannerAUX = JSON.parse(JSON.stringify(params.row));
      this.selectedBanner = BannerAUX;
    },
    getBannerData() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/BannerInvasivo",
            body: JSON.stringify({
              opt: "V",
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.rows = res.data.lsBanners;
              resolve();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.error = e.message;
            reject();
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" >
table.vgt-table {
  font-size: 13px !important;
}
.iconEmpty {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
